<template>
  <vx-card id="table-list">
    <!-- seach -->
    <div class="flex flex-wrap mb-2">
      <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-2">
        <v-select v-model="filter.club" :options="memberClubsOptions" />
      </div>
      <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-2 md:ml-2">
        <v-select v-model="filter.status" :options="giftSetsStatusOptions" />
      </div>
      <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-2 md:ml-2">
        <v-select v-model="filter.gift_set" :options="giftSetsListOptions" />
      </div>
      <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-2 md:ml-2">
        <vs-button type="filled" @click.prevent="handleSearch()"
          >SEARCH</vs-button
        >
      </div>
    </div>

    <!-- table -->
    <custom-vs-table
      ref="table"
      pagination
      stripe
      sst
      hoverFlat
      multiple
      v-model="selected"
      :data="lists.data"
      :max-items="lists.meta.pagination.per_page"
      :total="lists.meta.pagination.total"
      @change-page="handleChangePage"
    >
      <template slot="thead">
        <vs-th class="thead">No</vs-th>
        <vs-th class="thead">Club</vs-th>
        <vs-th class="thead">Member Name</vs-th>
        <vs-th class="thead">Gift Sets</vs-th>
        <vs-th class="thead">Status</vs-th>
        <vs-th class="thead">Date</vs-th>
        <vs-th class="thead">&nbsp;</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="item" :key="index" v-for="(item, index) in data">
          <vs-td>
            {{ rowNo(index) }}
          </vs-td>
          <vs-td>
            {{ lodash.startCase(lodash.get(item, 'member.club', '-')) }}
          </vs-td>
          <vs-td>
            {{ lodash.get(item, 'member.name', '-') }}
          </vs-td>
          <vs-td>
            {{ lodash.get(item, 'gift_set.name', '-') }}
          </vs-td>
          <vs-td>
            <cell-renderer-status :status="item.status" />
          </vs-td>
          <vs-td>
            <span>
              {{ $helpers.dateFormat(item.created_at) }}
            </span>
          </vs-td>
          <vs-td>
            <vs-button
              class="float-left"
              color="primary"
              type="border"
              icon-pack="feather"
              icon="icon-search"
              @click="
                showProfile({
                  member_id: lodash.get(item, 'member_id'),
                  profile_id: lodash.get(item, 'profile_id'),
                })
              "
            />
          </vs-td>
        </vs-tr>
      </template>
    </custom-vs-table>

    <!-- SIDEBAR -->
    <vs-sidebar
      v-model="quickview"
      position-right
      hidden-background
      parent="#table-list"
      default-index="1"
      color="primary"
      class="sidebarx"
      spacer
    >
      <vs-divider border-style="dashed" color="primary">Member</vs-divider>
      <div class="flex flex-wrap px-5">
        <div class="w-full mt-5">
          <h6>Name:</h6>
          <p>{{ lodash.get($profileMember, 'profile.name') || '-' }}</p>
        </div>
        <div class="w-full mt-5">
          <h6>Email:</h6>
          <p>{{ lodash.get($profileMember, 'profile.email') || '-' }}</p>
        </div>
        <div class="w-full mt-5">
          <h6>Contact Number:</h6>
          <p>{{ lodash.get($profileMember, 'profile.phone_number') || '-' }}</p>
        </div>
        <div class="w-full mt-5">
          <h6>Contact Channel:</h6>
          <p>
            {{
              mergeContactChannel(
                lodash.get($profileMember, 'profile.contact_channel', '-')
              )
            }}
          </p>
        </div>
        <div class="w-full mt-5">
          <h6>Converted Customer:</h6>
          <p>
            {{
              lodash.get($profileMember, 'profile.is_customer_converted', false)
                ? 'Yes'
                : 'No'
            }}
          </p>
        </div>
      </div>

      <vs-divider border-style="dashed" color="primary">Children</vs-divider>
      <div class="flex flex-wrap px-5">
        <div class="w-full mt-5">
          <h6>Name:</h6>
          <p>{{ lodash.get($profileMember, 'children.name') || '-' }}</p>
        </div>
        <div
          class="w-full mt-5"
          v-if="lodash.get($profileMember, 'children.pregnant') === false"
        >
          <h6>Birthdate:</h6>
          <p>{{ lodash.get($profileMember, 'children.birthdate') || '-' }}</p>
        </div>
        <div
          class="w-full mt-5"
          v-if="lodash.get($profileMember, 'children.pregnant') === true"
        >
          <h6>Due Date:</h6>
          <p>
            {{
              lodash.get($profileMember, 'children.estimated_due_date') || '-'
            }}
          </p>
        </div>
      </div>

      <vs-divider border-style="dashed" color="primary">Address</vs-divider>
      <div class="flex flex-wrap px-5">
        <!-- <div class="w-full mt-5">
          <p>{{ mergeAddress(lodash.get($profileMember, 'address')) }}</p>
        </div> -->
        <div class="w-full mt-5">
          <h6>House Number:</h6>
          <p>{{ lodash.get($profileMember, 'address.house') || '-' }}</p>
        </div>
        <div class="w-full mt-5">
          <h6>Street:</h6>
          <p>{{ lodash.get($profileMember, 'address.street') || '-' }}</p>
        </div>
        <div class="w-full mt-5">
          <h6>Ward:</h6>
          <p>{{ lodash.get($profileMember, 'address.ward') || '-' }}</p>
        </div>
        <div class="w-full mt-5">
          <h6>District:</h6>
          <p>{{ lodash.get($profileMember, 'address.district') || '-' }}</p>
        </div>
        <div class="w-full mt-5">
          <h6>City:</h6>
          <p>{{ lodash.get($profileMember, 'address.city') || '-' }}</p>
        </div>
        <div class="w-full mt-5">
          <h6>Zip Code:</h6>
          <p>{{ lodash.get($profileMember, 'address.zip_code') || '-' }}</p>
        </div>
      </div>

      <!-- <div class="flex flex-wrap px-5 mt-5">
        <div class="w-full mt-5">
          <h4>Gift Sets</h4>
        </div>
        <div class="w-full mt-5"
          v-for="item in lodash.get($profileMember, 'gift_sets.current')"
          :key="`request-${item.id}`">
          <div class="flex">
            <div class="w-2/3">
              {{ item.name }}
            </div>
            <div class="w-1/3">
              <vs-chip
                :color="getColorFromStatus(item.status)">
                {{ item.status }}
              </vs-chip>
            </div>
          </div>
        </div>
      </div> -->
    </vs-sidebar>

    <!-- ACTION - DROPDOWN -->
    <vs-dropdown
      vs-trigger-click
      class="cursor-pointer"
      v-show="!lodash.isEmpty(lists.data)"
    >
      <div
        class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32"
      >
        <span class="mr-2 leading-none">Actions</span>
        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
      </div>

      <vs-dropdown-menu>
        <vs-dropdown-item @click.prevent="updateStatus('approve')">
          <span class="flex items-center">
            <feather-icon icon="CheckIcon" svgClasses="h-4 w-4" class="mr-2" />
            <span>Approve</span>
          </span>
        </vs-dropdown-item>

        <vs-dropdown-item @click.prevent="updateStatus('reject')">
          <span class="flex items-center">
            <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
            <span>Reject</span>
          </span>
        </vs-dropdown-item>

        <vs-dropdown-item @click.prevent="updateStatus('cancel')">
          <span class="flex items-center">
            <feather-icon icon="XIcon" svgClasses="h-4 w-4" class="mr-2" />
            <span>Cancel</span>
          </span>
        </vs-dropdown-item>

        <vs-dropdown-item @click.prevent="updateStatus('process')">
          <span class="flex items-center">
            <feather-icon
              icon="ArchiveIcon"
              svgClasses="h-4 w-4"
              class="mr-2"
            />
            <span>Process</span>
          </span>
        </vs-dropdown-item>

        <vs-dropdown-item @click.prevent="updateStatus('complete')">
          <span class="flex items-center">
            <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2" />
            <span>Complete</span>
          </span>
        </vs-dropdown-item>

        <vs-dropdown-item @click.prevent="exportResult">
          <span class="flex items-center">
            <feather-icon
              icon="DownloadIcon"
              svgClasses="h-4 w-4"
              class="mr-2"
            />
            <span>Export</span>
          </span>
        </vs-dropdown-item>
      </vs-dropdown-menu>
    </vs-dropdown>

    <vs-progress
      indeterminate
      color="success"
      v-if="is_generating"
    ></vs-progress>
  </vx-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

// import Datepicker from 'vuejs-datepicker'
import VSelect from 'vue-select'
import FileSaver from 'file-saver'

import listMixin from '@/mixins/listMixin'
import constants from '@/utils/constants'
import helpers from '@/utils/helpers'

import CustomVsTable from '@/components/table/CustomVsTable'
import CellRendererStatus from '@/components/table/cell-renderer/GiftSetsRequestStatus'

import '@/laravelEcho'

export default {
  mixins: [listMixin],
  components: {
    VSelect,
    CustomVsTable,
    CellRendererStatus,
  },
  data () {
    return {
      tabIndex: null,
      giftSetsStatus: constants.giftSetsStatus,
      giftSetsStatusOptions: constants.giftSetsStatusOptions,
      selected: [],
      params: {
        club: null,
        gift_set: null,
        status: null,
      },
      filter: {
        club: null,
        gift_set: null,
        status: null,
      },
      is_generating: false,
      quickview: false,
    }
  },

  computed: {
    ...mapGetters('data', {
      $giftSets: 'giftSets',
    }),
    ...mapGetters('member', {
      $profileMember: 'profileQuickView',
    }),
    ...mapGetters('giftSetsRequest', ['lists', 'uuid']),

    memberClubsOptions () {
      return constants.memberClubs.map((club) => ({
        value: club.key,
        label: club.name,
      }))
    },
    giftSetsListOptions () {
      if (!this.lodash.isArray(this.$giftSets) || this.$giftSets.length === 0) {
        return []
      }

      return this.$giftSets.map((giftSets) => ({
        value: giftSets.id,
        label: giftSets.name,
      }))
    },
  },
  methods: {
    ...mapActions('data', ['fetchGiftSets']),
    ...mapActions('member', {
      fetchProfile: 'quickviewProfile',
    }),
    ...mapActions('giftSetsRequest', [
      'fetch',
      'bulksStatus',
      'generate',
      'download',
      'resetExport',
    ]),

    mergeContactChannel (contact_channel) {
      if (this.lodash.isArray(contact_channel)) {
        return contact_channel.join(', ')
      }

      return this.lodash.isEmpty(contact_channel) ? '-' : contact_channel
    },

    mergeAddress (address) {
      const arr = []

      const props = [
        'house',
        'street',
        'ward',
        'district',
        'city',
        'zip_code',
      ]
      props.forEach((prop) => {
        if (!this.lodash.isEmpty(this.lodash.get(address, prop))) {
          arr.push(this.lodash.get(address, prop))
        }
      })

      return arr.length ? arr.join(' ') : '-'
    },

    getColorFromStatus (status) {
      switch (status) {
      case 'waiting':
      case 'process':
        return 'warning'

      case 'approved':
      case 'completed':
        return 'success'

      case 'rejected':
      case 'canceled':
        return 'danger'
      }

      return 'primary'
    },

    async _fetch () {
      this.locked = true

      this.selected = []

      await this.fetch({ ...this.lodash.get(this, 'params', {}) })

      this.locked = false
    },

    handleFilter () {
      this.$router
        .push({
          query: helpers.removePropertyIsEmpty({
            club: this.params.club,
            status: this.params.status,
            gift_set: this.params.gift_set,
          }),
        })
        .catch(() => {})

      this.selected = []

      this.handleSearch()
    },
    async showProfile ({ member_id, profile_id }) {
      this.quickview = true
      await this.fetchProfile({
        memberId: member_id,
        profileId: profile_id,
      })
    },

    async updateStatus (status) {
      if (this.selected.length === 0) {
        this.$vs.notify({
          title: 'Warning',
          text: 'Please select at least one',
          iconPack: 'feather',
          icon: 'icon-error',
          color: 'warning',
        })
        return
      }
      const ids = this.selected.map(({ id }) => id).sort()

      this.locked = true

      await Promise.all([this.bulksStatus({ status, ids })])
        .then(() => {
          this.$vs.notify({
            title: 'Success',
            text: 'Bulks update status completed',
            iconPack: 'feather',
            icon: 'icon-check-circle',
            color: 'success',
          })

          this._fetch()
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Error',
            text: 'Bulks update fail',
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger',
          })
        })
        .finally(() => {
          this.locked = false
        })
    },

    async exportResult () {
      const _params = this.lodash.pick(this.params, [
        'club',
        'gift_set',
        'status',
      ])

      Object.assign(_params, {
        ids: this.selected.map(({ id }) => id).sort(),
      })

      this.locked = true

      await this.generate({ ..._params })
        .then(() => {})
        .catch(() => {
          this.$vs.notify({
            title: 'Error',
            text: 'Export result fail',
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger',
          })
        })
        .finally(() => {
          this.locked = false
        })
    },

    async downloadResult (uuid) {
      this.locked = true

      await this.download({ uuid })
        .then((response) => {
          const disposition = this.lodash.get(
            response,
            'headers.content-disposition',
          )

          let fileName = 'export.xlsx'
          if (disposition && disposition.indexOf('attachment') !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
            const matches = filenameRegex.exec(disposition)
            if (matches !== null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, '')
            }
          }

          FileSaver.saveAs(new Blob([response.data]), fileName)

          this.resetExport()

          this.$vs.notify({
            title: 'Success',
            text: 'Result has been generated',
            iconPack: 'feather',
            icon: 'icon-check-circle',
            color: 'success',
          })
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Error',
            text: 'Result unable to generate',
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger',
          })
        })
        .finally(() => {
          this.locked = false
        })
    },
  },

  async beforeMount () {
    if (this.$giftSets.length === 0) {
      await this.fetchGiftSets()
    }

    if (this.lodash.has(this.$route, 'query.club')) {
      const $value = this.lodash.toInteger(
        this.lodash.get(this.$route, 'query.club'),
      )
      this.filter.club =
        this.memberClubsOptions.find(({ value }) => value === $value) || null
    }

    this.filter.status =
      this.giftSetsStatusOptions.find(
        ({ value }) => value === this.lodash.get(this.$route, 'query.status', 'waiting'),
      ) || null

    if (this.lodash.has(this.$route, 'query.gift_set')) {
      const $value = this.lodash.toInteger(
        this.lodash.get(this.$route, 'query.gift_set', 0),
      )
      this.filter.gift_set =
        this.giftSetsListOptions.find(({ value }) => value === $value) || null
    }

    this.params.club = this.lodash.get(this.filter, 'club.value')
    this.params.status = this.lodash.get(this.filter, 'status.value')
    this.params.gift_set = this.lodash.get(this.filter, 'gift_set.value')
  },

  async mounted () {
    const self = this

    this.resetExport()

    this._fetch()

    window.Echo.channel('gift-sets-requests')
      .listen('.generated', function (data) {
        setTimeout(function () {
          if (
            !self.lodash.isEmpty(self.uuid) &&
            self.lodash.get(data, 'uuid') === self.uuid
          ) {
            self.downloadResult(self.uuid)
          }
        }, process.env.VUE_APP_EXPORT_DOWNLOAD_DELAY)
        // app.messages.push(JSON.stringify(data));
      })
      .listen('.exception', function (data) {
        if (
          !self.lodash.isEmpty(self.uuid) &&
          self.lodash.get(data, 'uuid') === self.uuid
        ) {
          self.resetExport()

          self.$vs.notify({
            title: 'Exception',
            text: self.lodash.get(data, 'message', 'Unable to generate'),
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger',
          })
        }
      })
  },

  beforeDestroy () {
    window.Echo.leaveChannel('gift-sets-requests')
  },

  watch: {
    // $giftSets (to) {
    //   this.lists = to
    // }
    'filter.club' (to) {
      this.params.club = this.lodash.get(to, 'value')

      this.handleFilter()
    },
    'filter.gift_set' (to) {
      this.params.gift_set = this.lodash.get(to, 'value')

      this.handleFilter()
    },
    'filter.status' (to) {
      this.params.status = this.lodash.get(to, 'value')

      this.handleFilter()
    },
    uuid (to) {
      this.is_generating = !this.lodash.isEmpty(to)
    },
  },
}
</script>
