
export default {
  data:() => ({
    locked: null,
    loaded: false,
    keyword: '',
    page: 0,
    params: {
      search: '',
      page: 0,
    },
  }),

  methods: {
    showLoading () {
      this.$vs.loading()
      this.lodash.set(this, 'loaded', false)
    },
    hideLoading () {
      this.$vs.loading.close()
      this.lodash.set(this, 'loaded', true)
    },
    async handleSearch (searching) {
      this.locked = true

      // console.log({ methods: 'handleSearch', searching })

      this.lodash.set(this, 'params.page', 1)

      if (!this.lodash.isUndefined(searching)) {
        this.lodash.set(this, 'params.search', searching)
      }
      // console.log({ ...this.lodash.get(this, 'params', {}) })

      await this.fetch({ ...this.lodash.get(this, 'params', {}) })

      this.locked = false
    },
    async handleChangePage (page) {
      this.locked = true

      this.lodash.set(this, 'params.page', page)

      await this.fetch({ ...this.lodash.get(this, 'params', {}) })

      this.locked = false
    },
    rowNo (index) {
      const current_page = this.lodash.get(this.lists, 'meta.pagination.current_page', 0)
      const per_page = this.lodash.get(this.lists, 'meta.pagination.per_page', 1)

      return index + 1 + (current_page > 1 ? (current_page - 1) * per_page : 0)
    },
    async remove (id) {
      this.$swal({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#ea5455',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {

        if (result.value) {
          this.delete({ id })
            .then(() => {
              this.fetch({
                search: this.lodash.get(this, 'keyword'),
                page: this.lodash.get(this, 'page'),
              })
            })
        }
      })
    },
  },

  beforeMount () {
    this.locked = true
  },

  mounted () {
    this.locked = false
  },

  beforeDestroy () {
    if (!this.lodash.isUndefined(this.reset)) {
      // console.log({ event: 'beforeDestroy', action: 'reset' })
      this.reset()
    }

    this.params.search = this.lodash.get(this.$route, 'query.search', '')
  },
  watch: {
    locked (value) {
      // console.log({ watch: 'locked', value })
      if (value === true) {
        this.$vs.loading()
      } else {
        this.$vs.loading.close()
      }
    },
    // lists () {
    //   // this.hideLoading()
    //   this.locked = false
    // }
  },
}
